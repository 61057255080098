import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "./members-page.css"
import MembersCard from "./members-card"

const MembersPage = () => {
  const data = useStaticQuery(graphql`
  query {
      imgana: file(relativePath: {eq: "members-img/ana.jpg"}){
          childImageSharp{
              fixed(width: 180){
                ...GatsbyImageSharpFixed
              }
          }
      }
      imgbia: file(relativePath: {eq: "members-img/bia.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgbibi: file(relativePath: {eq: "members-img/bibi.png"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgfer: file(relativePath: {eq: "members-img/fer.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imggi: file(relativePath: {eq: "members-img/gi.jpeg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgisa: file(relativePath: {eq: "members-img/isa.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgjess: file(relativePath: {eq: "members-img/jess.jpeg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgje: file(relativePath: {eq: "members-img/je.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imglau: file(relativePath: {eq: "members-img/lau.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imglaurinha: file(relativePath: {eq: "members-img/laurinha.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgmi: file(relativePath: {eq: "members-img/mi.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgnina: file(relativePath: {eq: "members-img/nina.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgpedrina: file(relativePath: {eq: "members-img/pedrina.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgbel: file(relativePath: {eq: "members-img/bel.jpeg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgclari: file(relativePath: {eq: "members-img/clari.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgjulia: file(relativePath: {eq: "members-img/julia.jpeg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imglala: file(relativePath: {eq: "members-img/lala.jpeg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imglali: file(relativePath: {eq: "members-img/lali.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgluiza: file(relativePath: {eq: "members-img/luiza.jpeg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgoli: file(relativePath: {eq: "members-img/oli.jpeg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgvi: file(relativePath: {eq: "members-img/vitoria.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgsthe: file(relativePath: {eq: "members-img/sthe.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
      imgcarol: file(relativePath: {eq: "members-img/carol.jpg"}){
        childImageSharp{
            fixed(width: 180){
              ...GatsbyImageSharpFixed
            }
        }
      }
    }
 `)

return(
  <div className="members-page-container">
    <p className="members-page-title">NOSSAS INTEGRANTES</p>
    <div className="members-flex">
      {/*
      <MembersCard
        fixed= { data.imgana.childImageSharp.fixed }
        name="Ana Beatriz Mitsutani"
        description="Estudante de Engenharia de Produção"
        insta="https://www.instagram.com/ana_mitsutani/"
        linkedin="https://www.linkedin.com/in/ana-beatriz-mitsutani-428052191/"
      />
      <MembersCard
        fixed= { data.imgbibi.childImageSharp.fixed }
        name="Beatriz Bicudo"
        description="Estudante de Engenharia Elétrica com ênfase em Energia e Automação Elétrica"
        insta="https://www.instagram.com/bia_bicudo/"
        linkedin="https://www.linkedin.com/in/beatriz-bicudo"
      />
      */}
      <MembersCard
        fixed= { data.imgcarol.childImageSharp.fixed }
        name="Ana Carolina Macedo"
        description="Estudante de Engenharia de Materiais"
        insta="https://www.instagram.com/anacarol_macedo/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgbia.childImageSharp.fixed }
        name="Beatriz Passanezi"
        description="Estudante de Engenharia Elétrica com ênfase em Sistemas Eletrônicos"
        insta="https://www.instagram.com/bpassanezi/"
        linkedin="https://www.linkedin.com/in/beatriz-passanezi/"
      />
      <MembersCard
        fixed= { data.imgclari.childImageSharp.fixed }
        name="Clarice Mosken"
        description="Estudante de Engenharia Civil"
        insta="https://www.instagram.com/cmosken/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgfer.childImageSharp.fixed }
        name="Fernanda Kanazawa"
        description="Estudante de Engenharia Mecatrônica"
        insta="https://www.instagram.com/fe.kanazawa/"
        linkedin="https://www.linkedin.com/in/fernanda-tiemi-kanazawa"
      />
      <MembersCard
        fixed= { data.imgbel.childImageSharp.fixed }
        name="Isabella Ferraz"
        description="Estudante de Engenharia de Produção"
        insta="https://www.instagram.com/_isabella.ferraz/"
        linkedin="#"
      />
      {/*<MembersCard
        fixed= { data.imggi.childImageSharp.fixed }
        name="Giovanna Rodrigues"
        description="Estudante de Engenharia Civil"
        insta="https://www.instagram.com/gih.gih/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgisa.childImageSharp.fixed }
        name="Isabelle Ritter Vargas"
        description="Estudante de Engenharia de Computação"
        insta="https://www.instagram.com/isav.x/"
        linkedin="https://www.linkedin.com/in/isabellevargas"
      />*/}
      <MembersCard
        fixed= { data.imgje.childImageSharp.fixed }
        name="Jéssica Simplício"
        description="Graduada em Física"
        insta="https://www.instagram.com/jessimplicio/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgjess.childImageSharp.fixed }
        name="Jéssica Gonsalves Santos"
        description="Estudante de Engenharia Elétrica"
        insta="https://www.instagram.com/jess__gonsalves/"
        linkedin="https://www.linkedin.com/in/jéssicagonsalves/"
      />
      <MembersCard
        fixed= { data.imgjulia.childImageSharp.fixed }
        name="Julia Motta"
        description="Estudante de Engenharia da Computação"
        insta="#"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgnina.childImageSharp.fixed }
        name="Karina Bonami"
        description="Estudante de Engenharia Metalúrgica"
        insta="https://www.instagram.com/karina_bonami/"
        linkedin="https://www.linkedin.com/in/karina-bonami-vicente-154862153"
      />
      <MembersCard
        fixed= { data.imglala.childImageSharp.fixed }
        name="Larissa Faro"
        description="Estudante de Engenharia Mecânica"
        insta="https://www.instagram.com/lalafaro/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imglali.childImageSharp.fixed }
        name="Larissa Fontes"
        description="Estudante de Engenharia Mecatrônica"
        insta="https://www.instagram.com/fonteslaris/"
        linkedin="#"
      />
      {/*<MembersCard
        fixed= { data.imglaurinha.childImageSharp.fixed }
        name="Laura Barbosa"
        description="Estudante de Farmácia"
        insta="https://www.instagram.com/kra0n/"
        linkedin="#"
      />*/}
      <MembersCard
        fixed= { data.imglau.childImageSharp.fixed }
        name="Laura do Prado"
        description="Estudante de Engenharia Mecânica"
        insta="https://www.instagram.com/laura_pradogp/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgluiza.childImageSharp.fixed }
        name="Luiza Auricchio"
        description="Estudante de Engenharia de Materiais"
        insta="https://www.instagram.com/laura_pradogp/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgmi.childImageSharp.fixed }
        name="Milena Tanaka"
        description="Estudante de Engenharia de Materiais"
        insta="https://www.instagram.com/mimtanaka/"
        linkedin="https://www.linkedin.com/in/milenatanaka/"
      />
      <MembersCard
        fixed= { data.imgoli.childImageSharp.fixed }
        name="Olivia Kita"
        description="Estudante de Engenharia Química"
        insta="https://www.instagram.com/olivia.kita/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgsthe.childImageSharp.fixed }
        name="Sthefani Bigai"
        description="Estudante de Engenharia Civil"
        insta="https://www.instagram.com/sthebigai/"
        linkedin="#"
      />
      <MembersCard
        fixed= { data.imgvi.childImageSharp.fixed }
        name="Vitória Faria"
        description="Estudante de Engenharia Mecânica"
        insta="https://www.instagram.com/vih_faria/"
        linkedin="#"
      />
      {/*<MembersCard
        fixed= { data.imgpedrina.childImageSharp.fixed }
        name="Pedrina Vitória"
        description="Estudante de Engenharia Civil"
        insta="https://www.instagram.com/pedrinavitoriaassis/"
        linkedin="https://www.linkedin.com/in/pedrina-vitoria"
      />*/}
    </div>
  </div>
  )
}

export default MembersPage
