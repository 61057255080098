import React from "react"
import Img from "gatsby-image"
import "./members-card.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

const MembersCard = (props) => {
  return(
  <div className="members-card">
    <Img className="photo" alt="" fixed={props.fixed}/>
    <div className="members-card-text">
    <p className="name">{props.name}</p>
    <p className="description">{props.description}</p>
    <div className="members-social-icons">
      <a href={props.insta}>
        <div className="members-social-media-circle members-insta-circle">
          <FontAwesomeIcon
            className="members-social-media-icon insta-icon"
            icon={faInstagram}
          />
        </div>
      </a>
      <a href={props.linkedin}>
        <div className="members-social-media-circle members-linkedin-circle">
          <FontAwesomeIcon
            className="members-social-media-icon linkedin-icon"
            icon={faLinkedin}
          />
        </div>
      </a>
    </div>
    </div>
  </div>
  )
}

export default MembersCard
