import React from "react"
import { Helmet } from "react-helmet"
import "bootstrap/dist/css/bootstrap.min.css"
import EmmaHead from "../images/gatsby-icon.png"
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import MembersPage from "../components/members-page.js"

import "./members.css"

const Members = () => (
  <div className="members">
    <Helmet>
      <title>Integrantes</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
    <MyNavbar />
    <MembersPage />
    <MyFooter />
  </div>
)

export default Members
